import * as React from 'react';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify,
         useRedirect,
         useRefresh,
         fetchStart,
         fetchEnd,
         Button,
         Confirm } from 'react-admin';
import SendIcon from '@mui/icons-material/Send';
import Config from './config';
const conf = Config();

// see. https://marmelab.com/react-admin/doc/3.1/List.html#bulk-action-buttons
// <Confirm> <Confirm> leverages material-ui’s <Dialog> component to implement a confirmation popup
export const SendMailButton = ({record}) => {
    //console.log( JSON.stringify(conf.config.endpoint) );
    const refresh  = useRefresh();
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const notify   = useNotify();
    const auth     = JSON.parse( localStorage.getItem('forms.lanscope.jp:auth') );

    let token = '';
    if (auth){
        token = auth.token;
    }

    const [loading, setLoading] = useState(false);
    const sendMail = () => {
        //console.log(JSON.stringify(record));
        setLoading(true);
        dispatch( fetchStart() ); // start the global loading indicator
        try {
            fetch( conf.config.endpoint, {
                method: 'POST',
                headers: {
                    "Content-Type"  : "application/json",
                    "Authorization" : token ? `Bearer ${token}` : "",
                },
                body: JSON.stringify({
                    query:`mutation sendEmail($id: ID!){
                             sendEmail( id: $id ){
                               id
                             }
                          }`,
                    variables: {"id": record.id },
                }),
            })
            .then(() => {
                  notify('メールを送信しました');
                redirect('/Post');
                refresh();
            })
           .catch((e) => {
               notify( 'Error:メール送信に失敗しました', { type: 'warning' } )
           })
           .finally(() => {
              setLoading(false);
              dispatch(fetchEnd()); // stop the global loading indicator
          });
        } catch ( error ) {
            console.log(error);
        }
    }
    const handleClick       = () => setLoading(true);
    const handleDialogClose = () => setLoading(false);
    const handleConfirm     = () => {
        sendMail();
        setLoading(false);
    }
    return (
        <Fragment>
            <Button label="メールを再送する"
                    color="primary"
                    variant="contained"
                    size='medium'
                    endIcon={<SendIcon/>}
                    onClick={handleClick}
                    disabled={loading} />
            <Confirm
                isLoading={loading}
                isOpen={loading}
                title="メール再送"
                content="メールを再送しますか？"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                cancel="キャンセル"
                confirm="OK"
            />
        </Fragment>
    );
}

export default SendMailButton;
