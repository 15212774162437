import React, { useState, useEffect } from "react";
import { Admin, Login, Resource } from 'react-admin';

import buildGraphQLProvider from 'ra-data-graphql-simple';
import {PostList, PostEdit, PostCreate } from './posts';
import {PostShow} from './PostShow';

import { createMuiTheme } from '@material-ui/core/styles';
import authProvider from './authProvider';
import NotFound from './notfound';
import Config from './config';
import { createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import MyLayout from './MyLayout';
//import bg from './40316.jpg';
import bg from './login_bg_1600_900.png';
import grey from '@material-ui/core/colors/grey';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

const conf = Config();
const theme = createMuiTheme({
    palette: {
        primary: {
            main: grey["800"],
        },
        secondary: {
            main: grey["600"],
        },
    },
    props: {
        TextField: {
            variant: "outlined"
        }
    },
});

/*
        operationNames: {
            [GET_LIST]: resource => `all${pluralize(resource.name)}`,
            [GET_ONE]: resource => `${resource.name}`,
            [GET_MANY]: resource => `all${pluralize(resource.name)}`,
            [GET_MANY_REFERENCE]: resource => `all${pluralize(resource.name)}`,
            [CREATE]: resource => `create${resource.name}`,
            [UPDATE]: resource => `update${resource.name}`,
            [DELETE]: resource => `delete${resource.name}`,
        },
*/
//            theme={theme}
// dataProvider prop:
// a function capable of fetching data from an API.
// <Resource name="users" informs react-admin 2 fetch the "users" recs from
// https://<dataProvider>/users/
// <Resource> also defines each CURD ope. (list, create, edit, show)
const httpLink = createHttpLink({
    uri: conf.config.endpoint,
    //  uri: conf.config.endpoint,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
    const auth  = JSON.parse( localStorage.getItem('forms.lanscope.jp:auth') );
    let token = '';
    if (auth){
        token = auth.token;
    }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const MyLoginPage = () => (
        <Login
            backgroundImage={bg}
        />
);



const CircularIndeterminate = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
}

const LinearIndeterminate = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  );
}

const App = () => {
    const [ dataProvider, setDataProvider ] = useState(null);
    useEffect( () => {
        buildGraphQLProvider({
                clientOptions: { link: authLink.concat(httpLink), },
            })
        // ここで buildGQLProviderを dataProvider としてセットして、下記で <Admin>に渡す。任意実行される。
            .then( graphQlDataProvider => setDataProvider( () => graphQlDataProvider ) );
    }, []);

    if ( ! dataProvider ){
        return (
                <LinearIndeterminate />
               );
    }

    return(
        <Admin
            title="MOTEX app admin"
            theme={theme}
            layout={MyLayout}
            authProvider={authProvider}
            dataProvider={dataProvider}
            catchAll={NotFound}
            disableTelemetry
            loginPage={MyLoginPage}
        >
        <Resource
            name="Post"
            options={{ label:"申請書" }}
            show={ PostShow }
            list={ PostList }
            edit={ PostEdit }
            create={ PostCreate }
         />
    </Admin>
    );

}
export default App;
