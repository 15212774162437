import * as React from 'react';
import { Form } from 'react-final-form';
import { Button, InputAdornment  } from '@material-ui/core';
import Box from '@mui/material/Box';
import SearchIcon from '@material-ui/icons/Search';
import { TextInput,
         SelectInput,
         DateInput,
         useListContext }
from 'react-admin';

const PostFilterForm = () => {
  const {
    filterValues,
    setFilters,
    hideFilter
  } = useListContext();

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      hideFilter("main");
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
      <div>
          <Form onSubmit={onSubmit} initialValues={filterValues}>
              {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                      <Box display="flex" alignItems="flex-end"
                          sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              p: 0,
                              m: 0,
                              bgcolor: 'background.paper',
                          }}
                      >
                          <Box component="span" mr={1}>
                              {/* Commentable filter */}
                              <SelectInput
                                  label="製品"
                                  alwaysOn
                                  source="product"
                                  choices={[
                                      { id: null, name: 'すべて'},
                                      { id: '1', name: 'LANSCOPEオンプレミス' },
                                      { id: '2', name: 'LANSCOPEクラウド' },
                                      { id: '3', name: 'CPMS BB' },
                                      { id: '4', name: 'CPMS DI' },
                                      { id: '5', name: 'REMO-CON' },
                                      { id: '6', name: 'SYNCPIT' },
                                  ]}
                              />&nbsp;&nbsp;&nbsp;&nbsp;
                              <SelectInput
                                  label="状態"
                                  alwaysOn
                                  source="status"
                                  choices={[
                                      {id: null, name: 'すべて'},
                                      { id: '1', name: 'EU入力待ち' },
                                      { id: '2', name: '入力完了' },
                                      { id: '3', name: 'EU入力完了' },
                                      { id: '4', name: 'SFA登録済み' },
                                  ]}
                              />&nbsp;&nbsp;&nbsp;&nbsp;
                              <TextInput
                                  resettable
                                  helperText={false}
                                  source="keywords"
                                  label="フリーワード検索"
                                  InputProps={{
                                      endAdornment: (
                                          <InputAdornment>
                                              <SearchIcon color="disabled" />
                                          </InputAdornment>
                                      )
                                  }}
                              />&nbsp;&nbsp;&nbsp;&nbsp;
                              <SelectInput
                                  label="入力待ち経過日数"
                                  alwaysOn
                                  source="passed"
                                  choices={[
                                      { id: '1', name: '1' },
                                      { id: '2', name: '2' },
                                      { id: '3', name: '3' },
                                      { id: '4', name: '4' },
                                      { id: '5', name: '5' },
                                      { id: '6', name: '6' },
                                  ]}
                              />
                          </Box>
                      </Box>
                      <Box
                          sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              p: 0,
                              m: 0,
                              bgcolor: 'background.paper',
                          }}
                      >
                          <Box sx={{ fontSize: 13, p: 3.8, m: -2.5, width:60}}>
                                  販社<br/>入力期間
                              </Box>
                              <Box
                                  sx={{
                                      px: 1,
                                      m:0,
                                      width: 120
                                  }}
                              >
                                  <DateInput source="p_start" label="この日から" alwaysOn/>
                              </Box>
                          <Box sx={{ p:2, m:0, width:15}}>
                          </Box>
                              <Box sx={{px: 1, m:0, width: 120 }}>
                                  <DateInput source="p_commit" label="この日まで" alwaysOn />
                              </Box>
                          <Box sx={{ fontSize: 13, p: 3.8, m: -2.5, width:60}}>
                          </Box>
                          <Box sx={{ fontSize: 13, p: 3.8, m: -2.5, width:60}}>
                                  EU<br/>入力期間
                              </Box>
                              <Box
                                  sx={{
                                      px: 1,
                                      m:0,
                                      width: 120
                                  }}
                              >
                                  <DateInput source="e_start" label="この日から" alwaysOn/>
                              </Box>
                          <Box sx={{ p: 2, m:0, width:15}}>
                          </Box>
                              <Box sx={{px: 1, m:0, width: 120 }}>
                                  <DateInput source="e_commit" label="この日まで" alwaysOn />
                              </Box>
                      </Box>
                      <Box display="flex" alignItems="flex-end" mb={1}
                          sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              p: 0,
                              m: 0,
                              bgcolor: 'background.paper',
                          }}
                      >
                          <Box component="span" mr={2} mb={1.5}>
                              <Button variant="outlined" color="primary" type="submit">
                                  検索
                              </Button>
                          </Box>
                          <Box component="span" mb={1.5}>
                              <Button variant="outlined" onClick={resetFilter}>
                                  リセット
                              </Button>
                          </Box>
                      </Box>
                  </form>
              )}
          </Form>
      </div>
  );
};

export default PostFilterForm;
