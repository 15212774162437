import * as React from "react";
import { List,
         Datagrid,
         ShowButton,
         ListButton,
         Edit,
         Create,
         SimpleForm,
         TextInput,
         TopToolbar,
         TextField,
         RichTextField,
         Show,
         SimpleShowLayout,
         DateField,
         useNotify,
         useRefresh,
         useRedirect
       } from 'react-admin';
import ListHeading from './listheading';
import {
         makeStyles, Box
       } from '@material-ui/core';
import PostFilterForm from './FilterForm';
import classnames from 'classnames';
// list データ、デフォルトレイアウトの描画(title,button,filters,pagenation)
// データのレコードは <Datagrid> が受け持つ
// <ListContext> ... <list>がデータをとってきて、listCOntextに入れる

const useStyles = makeStyles({
    actions: {
        backgroundColor: '#white',
    },
});

const useStylesStatus = makeStyles({
    green: {color: 'green' },
    red: { color: 'red', 'font-weight': 'bold' },
});

const postRowStyle = (record, index) => ({
    backgroundColor: index % 2 === 0 ? 'white' : '#eee',
    fontSize: '1rem',
});
//{id, record, resource}
const PostPanel = ({id, record, resource}) => (
    <ListHeading id={id} record={record} resource={resource} />
);

// CSVボタンを削除するためからの TopToolbarを作っている
const PostListActions = ({basePath, data, resource}) => (
    <Box width="100%">
        <PostFilterForm/>
    </Box>
);
const ListTitle = (props) => (
    <>
        申請書一覧
    </>
);

const ColoredTextField = props => {
    const classes = useStylesStatus();
    return (
        <TextField
            className={classnames({
                [classes.red]: typeof props !== 'undefined' && props.record[props.source] === 'EU入力待ち',
                [classes.green]: typeof props !== 'undefined' && props.record[props.source] !== 'EU入力待ち',
            })}
            {...props}
        />
    );
};

// <List> でアクセス可能なprops
// actions/aside/bulkActionButtons/component,empty, exporter,filter/filterDefautValues
// filters (Areact element,used to display the fileter form), pagenation,perPage, sort,
// title, syncWIthLocation
export const PostList = (props) => {
    const classes = useStyles(props);
    return (
        <List title={<ListTitle />}
              actions={<PostListActions />}
              classes={{ content: classes.actions }}
              {...props}>
            <>
                <Datagrid rowClick="expand" rowStyle={postRowStyle} expand={<PostPanel />}>
                <TextField source="app_num" label="受付番号"/>
                <RichTextField source="prod" label="製品"/>
                <TextField source="type_name" label="種別" />
                <RichTextField source="p_company" label="販社名"/>
                <RichTextField source="p_name"     label="担当" sortable={false}/>
                <TextField source="input_method"   label="入力方法" sortable={false}/>
                <TextField source="date_passed"    label="経過" sortable={false}/>
                <TextField source="high_channel"   label="商流販社" sortable={false}/>
                    <ColoredTextField source="status" label="状態" />
                <ShowButton label="詳細"/>
                </Datagrid>
            </>
        </List>
    );
}


const PostTitle = ( {record} ) => {
    return <span>申請書: {record ? `"${record.title}"` : ''}</span>;
};

export const PostEdit = (props) => (
    <Edit title={<PostTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="p_company" label="販社名"/>
            <TextInput source="p_name" label="担当者"/>
            <TextInput multiline source="p_name" />
        </SimpleForm>
    </Edit>
);

export const PostCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="p_company" />
            <TextInput source="rd" />
            <TextInput source="ud" />
        </SimpleForm>
    </Create>
);


const PostShowAction = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} label="一覧に戻る"/>
    </TopToolbar>
);

export const PostShow = ( props ) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onFailure = (error) => {
        notify(`could not load post: ${error.message}`);
        redirect('/posts');
        refresh();
    }

    return (
        <Show actions={<PostShowAction />} onFailure={onFailure} title="申請書詳細" {...props}>
            <SimpleShowLayout>
                <TextField source="p_company" label="販売店名"/>
                <TextField source="p_name" />
                <RichTextField source="mail_log_text" />
                <DateField label="ud" source ud />
            </SimpleShowLayout>
    </Show>
    );
};


export default PostList;
