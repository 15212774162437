export const Config = () => {

    if ( process.env.NODE_ENV === 'development' ) {
        //console.log('dev mode..');
        return {
            config: {
                endpoint: process.env.REACT_APP_BASE_URL,
            }
        }
    } else if (process.env.NODE_ENV === 'production') {
        //console.log('prod mode..');
        return {
            config: {
                endpoint: '/graphql/',
            }
        }
    } else {
        console.log(`prod mode.. ${process.env.NODE_ENV}` );
        return {
            config: {
                endpoint: process.env.REACT_APP_BASE_URL,
            }
        }
    }
}

export default Config;
