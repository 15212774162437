import Config from './config';
const conf = Config();

export const authProvider = {
    // login returns resolved/rejected promise
    login: ({ username, password }) => {
        const auth_body = JSON.stringify({
                query:`query auth($id: String!, $password: String!){
                             auth( id: $id, password: $password ){
                               token,
                               id,
                               email,
                               priv,
                             }
                          }`,
                variables: {'id': username, 'password': password },
        });
        const request = new Request(conf.config.endpoint, {
            method: 'POST',
            body: auth_body,
            headers: new Headers({ 'Content-Type': 'application/json'}),
        });
        return fetch(request)
            .then( res => {
                //console.log( 'res:' + res.status );
                if ( res.status < 200 || res.status >= 300 ){
                    // graphqlの場合は基本200なのでこの遷移はない.障害時のみだけ
                    throw new Error( res.status + '認証できませんでした(ServerError)');
                }
                return res.json();
            })
            .then( auth => {
                if (auth.errors){
                    //console.log( 'login res:' +  JSON.stringify(auth) );
                    throw new Error('401. 認証できませんした');
                } else {
                    //console.log( 'login res:' +  JSON.stringify(auth.data.auth) );
                    localStorage.setItem('forms.lanscope.jp:auth', JSON.stringify(auth.data.auth) );
                }
            })
            .catch(() => {
                throw new Error('402. 認証できませんでした')
            });
    },
    // called when the user clicks on the logout button.
    logout: () => {
        localStorage.removeItem('forms.lanscope.jp:auth');
        return Promise.resolve();
    },
    // called when the API return an error
    //checkError: ({message, extensions, networkError}) => {
    checkError: (props) => {
        //checkError: ({props}) => {
        //[Log] {"graphQLErrors":[{"message":"invalid session","extensions":{"category":"authentication"},"locations":[{"line":1,"column":23}],"path":["data"]}],"networkError":null,"message":"GraphQL error: invalid session"} (main.3b70791064713fd72001.hot-update.js, line 74)
        //console.log(JSON.stringify(props));
        var errors = props.graphQLErrors;
        //var json = JSON.parse(props);
        var message = errors[0].message;
        //console.log('networkError: ' + networkError );
        if ( message.indexOf('invalid session') !== -1 ){
            localStorage.removeItem('forms.lanscope.jp:auth');
            //console.log('checkerror:' + message );
          return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check4 auth
    checkAuth: (props) => {
        //var item = localStorage.getItem('forms.lanscope.jp:auth');
        //var json = JSON.parse(item);
        //console.log('lt: ' + JSON.stringify(item));
        return localStorage.getItem('forms.lanscope.jp:auth')
                    ? Promise.resolve()
                    : Promise.reject();
    },
    // called when the user navigates to a new locaiton to check for permission
    getPermissions: () => Promise.resolve(),
};

export default authProvider;
