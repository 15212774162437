import * as React from 'react';
import { Fragment, useState, cloneElement } from 'react';
import {
    TabbedShowLayout,
    Tab,
    TextInput,
    useNotify,
    useRefresh,
    useRedirect,
    Toolbar,
    email,
    useEditController,
    EditContextProvider,
    SimpleForm,
    Confirm,
    useUpdate,
    Button,
    required
} from 'react-admin';
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { Box } from '@material-ui/core';
import SendMailButton from "./SendMailButton";
import SaveIcon from '@mui/icons-material/Save';
import { useFormState } from 'react-final-form';

// メッセージカスタム
// https://marmelab.com/react-admin/CreateEdit.html#changing-the-success-or-failure-notification-message


const theme = createMuiTheme({
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
  },
});

const useStyles = makeStyles({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'start',
    },
});

// see it https://marmelab.com/react-admin/CreateEdit.html
const SomeSaveButton = ({ record }) => {
    const notify     = useNotify();
    const refresh    = useRefresh();
    const redirect   = useRedirect();
    const { values } = useFormState();
    const [ update, { loaded, error } ]
          = useUpdate('Post', record.id,
                      { id: record.id,
                        email: values.email,
                        note: values.note,
                      },
                      record
                     );

    const [ isLoading, setLoading ] = useState(false);
    const handleClick       = () => {
        setLoading(true);
    }
    const handleConfirm   = () => {
        if ( ! values.email ){
            notify(`メールアドレスが入力されていません`);
            setLoading(false);
            return;
        }
        update();
        setLoading(false);
    }
    const handleDialogClose = () => setLoading(false);

    if (error){
        notify(`保存に失敗しました:${error}`);
        redirect('/Post');
        refresh();
        return <p>ERROR</p>;
    }
    if (loaded){
        notify('保存しました');
        refresh();
    }
    return (
        <Fragment>
            <Button
                label="保存する"
                color="primary"
                variant="contained"
                size='medium'
                startIcon={<SaveIcon/>}
                disabled={isLoading}
                onClick={handleClick}
            />
            <Confirm
                isOpen={isLoading}
                isLoading={isLoading}
                title="保存"
                content="メールアドレスを保存しますか？"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                cancel="キャンセル"
                confirm="OK"
            />
        </Fragment>
    );
};

const CustomToolbar = props => {
    return (
    <Toolbar {...props} classes={useStyles()}>
        <SomeSaveButton {...props}/>
        <Box sx={{m:2}}>&nbsp;&nbsp;</Box>
        <SendMailButton {...props}/>
    </Toolbar> );
};

const MyEdit = props => {
    const controllerProps = useEditController(props);
    //console.log('arr:' + JSON.stringify(controllerProps.record) );
    const {
        basePath, // deduced from the location, useful for action buttons
        record, // record fetched via dataProvider.getOne() based on the id from the location
        redirect, // the default redirection route. Defaults to 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the update callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to update the record
        version, // integer used by the refresh feature
    } = controllerProps;
    return (
        <EditContextProvider value={controllerProps}>
            <div>
                {cloneElement(props.children, {
                    basePath,
                    record,
                    redirect,
                    resource,
                    save,
                    saving,
                    version,
                })}
            </div>
        </EditContextProvider>
    );
}
// https://marmelab.com/react-admin/Actions.html#usegetone
export const ListHeading = (props) => {

    return (
    <>
        <TabbedShowLayout syncWithLocation={false}>
            <Tab label="メール管理">
                <h4>商流販社情報</h4>
                <p>{props.record.p_company}</p>
                <hr/>
                <h4>メール送信履歴</h4>
                <div dangerouslySetInnerHTML={{ __html:props.record.mail_log_text}} />
            </Tab>
            { props.record.p_app_method === '1' &&
            <Tab label="文面を確認">
                <pre dangerouslySetInnerHTML={{ __html:props.record.mail_text}} />
            </Tab>
            }
            { props.record.p_app_method === '1' &&
            <Tab label="送信先メールアドレス変更">
                <MyEdit {...props} title="送信先EUメールアドレス変更">
                    <SimpleForm toolbar={<CustomToolbar />}>
                        <TextInput disabled source="p_app_mail" label="現在のメールアドレス"/>
                        <TextInput source="email" label="変更するメールアドレス" validate={ [ required(),email() ]}  />
                        <TextInput multiline source="note" label="変更する理由"/>
                    </SimpleForm>
                </MyEdit>
            </Tab>
            }
        </TabbedShowLayout>
    </>
    );
};

export default ListHeading;
