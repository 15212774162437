import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';

export const NotFound = () => (
        <Card>
        <Title title="NOT Found" />
        <CardContent>
        <h1>404: Page NOT FOUND</h1>
        </CardContent>
        </Card>
);

export default NotFound;
