import * as React from "react";
import { ListButton,
         TextField,
         UrlField,
         RichTextField,
         ChipField,
         Datagrid,
         DatagridBody,
         TopToolbar,
         Show,
         TabbedShowLayout,
         useRecordContext,
         ArrayField,
         Tab
       } from 'react-admin';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import { Box } from '@material-ui/core';
import { Divider } from '@mui/material';
import PropTypes from 'prop-types';

const PreField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    return <pre>{record[source]}</pre>;
}

TextField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};
const PostTitle = ({ record }) => {
    return(
    <>
        申請書 {record ? `${record.app_num} ${record.p_company}` : ''}
    </>
    );

};

const PostShowAction = ({ basePath, data }) => (
    <TopToolbar>
        <ListButton basePath={basePath} label="一覧に戻る"/>
    </TopToolbar>
);

const DatagridHeader = ({ children }) => (
    <></>
);

const MyDatagridRow = ({ record, resource, id, onToggleItem, children, selected, selectable, basePath }) => {
    const json = JSON.parse(id);
    return(
        <RaBox display="flex">
                <RaBox display="flex" flexWrap="wrap" flexGrow={1}>
                    <RaBox flex="0 0 100%" display="flex">
                        <h4>▼ 契約情報({json.id})</h4>
                    </RaBox>
                    {React.Children.map ( children, field => (

                        <>
                        <Box key={`${id}-${field.props.source}`} flex="0 0 40%"
                             sx={{ fontSize: 13, p: 3, m: -2.8, color: '#757575', whiteSpace: 'pre-line' }}
                        >
                            {field.props.label}<br/><br/>
                            <Box sx={{ fontSize: 14, p: 3, m: -2.8, color: '#000000' }}>
                                {record[field.props.source] ? record[field.props.source] : '-' }
                            </Box>
                            <Divider />
                        </Box>
                        </>

            ))}
                </RaBox>
        </RaBox>
    );
};

const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;

const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;

export const PostShow = ( props ) => {
    //console.log(JSON.stringify(props));
    return (

        <Show title={<PostTitle />} {...props} actions={<PostShowAction />}>
        <TabbedShowLayout syncWithLocation={false}>
            <Tab label="申請書詳細">
            <BoxedShowLayout>
                <RaBox display="flex" >
                    <RaBox display="flex" flexWrap="wrap" flexGrow={1}>
                        <RaBox flex="0 0 100%" display="flex">
                            <h4>▼ MOTEX受付情報</h4>
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="app_num" label="受付番号" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <ChipField source="type_name" label="申込種別" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <ChipField source="status" label="ステータス" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <ChipField source="input_method" label="入力方法" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="p_commit_date" label="販社申し込み日時"/>
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="e_commit_date" label="EU申し込み日時"/>
                        </RaBox>
                        <RaBox flex="0 0 40%" display="flex">
                            <ChipField source="delivery_option_text" label="納品先" />
                        </RaBox>
                        <RaBox flex="0 0 40%" display="flex">
                            <TextField source="delivery_mail_addr" label="納品先メールアドレス"/>
                        </RaBox>
                    </RaBox>
                </RaBox>
                <ArrayField source="products" label=''>
                            <MyDatagrid header={<DatagridHeader />}>
                                <TextField source="product_id" label="製品" />
                                <TextField source="term_type" label="契約期間" />
                                <TextField source="inherit_name" label="データ引き継ぎ" />
                                <TextField source="inheritance_lsp_id" label="引き継ぎ元登録ID" />
                                <TextField source="inheritance_org_name" label="組織名" />
                                <TextField source="inheritance_install_token" label="インストールトークン" />
                                <TextField source="inheritance_msp_name" label="引き継ぎMSP名" />
                                <TextField source="inheritance_user_name" label="引き継ぎユーザー名" />
                                <TextField source="connect" label="LNASCOPE連携の有無" />
                                <TextField source="connect_lsp_id" label="連絡先LANSCOPE-ID(myLANSCOPEID)" />
                                <TextField source="customer_id" label="保守ユーザーID/登録ID/組織名/MSP名" />
                                <TextField source="admin_email" label="管理者メールアドレス" />
                                <TextField source="contract_ym" label="保守サービス申込期間" />
                                <TextField source="delivery_date" label="納品日" />
                                <TextField source="activation_date" label="変更反映日/年" />
                                <TextField source="server_os" label="ご利用サーバー情報" />
                                <RichTextField source="est_num" label="エンドユーザー様向け連絡事項" />
                                <RichTextField source="note" label="メーカー向け連絡事項" />
                            </MyDatagrid>
                </ArrayField>
                <RaBox display="flex" >
                    <RaBox display="flex" flexWrap="wrap" flexGrow={1}>
                        <RaBox flex="0 0 100%" display="flex">
                            <h4>▼ エンドユーザー情報</h4>
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="e_company" label="会社名" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="e_full_name" label="担当者名" />
                        </RaBox>
                        <RaBox flex="0 0 20%" display="flex">
                            <TextField source="e_full_namek" label="担当者名(カナ)" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="e_tel"        label="電話番号" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="e_email"     label="メールアドレス" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="e_div"     label="部署名" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="e_zip"  label="郵便番号" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="e_pref" label="都道府県" />
                        </RaBox>
                        <RaBox flex="0 0 20%" display="flex">
                            <TextField source="e_address" label="住所" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="e_commit_date" label="EU申し込み日時"/>
                        </RaBox>
                    </RaBox>
                </RaBox>
                <RaBox display="flex" >
                    <RaBox display="flex" flexWrap="wrap" flexGrow={1} >
                        <RaBox flex="0 0 100%" display="flex">
                            <h4>▼ 販社情報</h4>
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="p_company" label="会社名" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="p_full_name" label="担当者名" />
                        </RaBox>
                        <RaBox flex="0 0 20%" display="flex">
                            <TextField source="p_full_namek" label="担当者名(カナ)" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="p_tel"        label="電話番号" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="p_email1"     label="メールアドレス" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="p_div"     label="部署名" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="p_zip"  label="郵便番号" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="p_pref" label="都道府県" />
                        </RaBox>
                        <RaBox flex="0 0 20%" display="flex">
                            <TextField source="p_address" label="住所" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="p_commit_date" label="販社申し込み日時"/>
                        </RaBox>
                    </RaBox>
                </RaBox>
            </BoxedShowLayout>
            </Tab>
            <Tab label="システム情報1">
            <BoxedShowLayout>
                <RaBox display="flex" >
                    <RaBox display="flex" flexWrap="wrap" flexGrow={1}>
                        <RaBox flex="0 0 100%" display="flex">
                            <h4>JSON</h4>
                        </RaBox>
                        <RaBox flex="0 0 100%" display="flex">
                            <PreField source="json" label="JSON" />
                        </RaBox>
                        <RaBox flex="0 0 100%" display="flex">
                            <h4>メール関連</h4>
                        </RaBox>
                        <RaBox flex="0 0 100%" display="flex">
                            <RichTextField source="mail_log_text" label="メールログ" />
                        </RaBox>
                        <RaBox flex="0 0 100%" display="flex">
                            <PreField source="mail_text" label="メール送信履歴" />
                        </RaBox>
                    </RaBox>
                </RaBox>
            </BoxedShowLayout>
            </Tab>
            <Tab label="システム情報2">
                <BoxedShowLayout>
                <RaBox display="flex" >
                    <RaBox display="flex" flexWrap="wrap" flexGrow={1} >
                        <RaBox flex="0 0 100%" display="flex">
                            <h4>▼ メタ情報</h4>
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="id" label="ID" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="token" label="token" />
                        </RaBox>
                        <RaBox flex="0 0 20%" display="flex">
                            <TextField source="eu_token" label="eu_token" />
                        </RaBox>
                        <RaBox flex="0 0 80%" display="flex">
                            <UrlField source="sfa_url" label="SFA URL" />
                        </RaBox>
                        <RaBox flex="0 0 80%" display="flex">
                            <TextField source="search_prod" label="検索ワード" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="p_ip"        label="販売店 IPアドレス" />
                        </RaBox>
                        <RaBox flex="0 0 50%" display="flex">
                            <TextField source="p_ua"     label="販売店 UA" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="e_ip"     label="EU IPアドレス" />
                        </RaBox>
                        <RaBox flex="0 0 50%" display="flex">
                            <TextField source="e_ua"     label="EU UA" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="p_start_date"     label="販売店 入力開始日時" />
                        </RaBox>
                        <RaBox flex="0 0 50%" display="flex">
                            <TextField source="p_commit_date"  label="販売店 入力完了日時" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="e_start_date"     label="EU 入力開始日時" />
                        </RaBox>
                        <RaBox flex="0 0 50%" display="flex">
                            <TextField source="e_commit_date"  label="EU 入力完了日時" />
                        </RaBox>
                        <RaBox flex="0 0 30%" display="flex">
                            <TextField source="rd" label="システム登録日時" />
                        </RaBox>
                        <RaBox flex="0 0 20%" display="flex">
                            <TextField source="ud" label="システム更新日時" />
                        </RaBox>
                    </RaBox>
                </RaBox>
                </BoxedShowLayout>
            </Tab>
            </TabbedShowLayout>
        </Show>

    );

};

export default PostShow;
